.container {
  position: relative;
  margin: 0 auto;
  width: 225px;
}

.thumbnail{
  width: 175px;
  height: 120px;
  object-fit: cover;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 3px #ccc;
}