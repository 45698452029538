.loading-box{
    padding: 12px;
    width: 64px;
    background:white;
    border-radius:15px;
    text-align:center;
    position:fixed;
    bottom: 20px;
    right: 20px;
}

.loading-box img{
	box-sizing: border-box;
    width:100%;
    padding:14%;
}
