.thumbnails{
	display:flex;
	flex-wrap: wrap;
}

.reorder-thumb{
	margin:0.5rem;
	height:120px;
}

.reorder-thumb .photo{
    max-height: 100%;
    max-width: 100%;
}

.reorder-thumb.video-thumbnail{
    position: relative;
}

.reorder-thumb.video-thumbnail .photo{
    opacity: 0.7;
}

.reorder-thumb .video_label{
    position:absolute;
    top:0;
    left:0;
    width: 32px;
    height: 32px;
}
