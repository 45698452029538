.header-bar{
	position:fixed;
	top:0;
	background: lightgrey;
	background: linear-gradient(#ddd, #bbb);
	width:100%;
	left:0;
	z-index:2;
	box-shadow:0 1px 2px black;
}

.header-bar-contents{
	width:100%;
	max-width:1024px;
	margin:0 auto;
	margin-bottom:0.5rem;
	display:flex;
	align-items:baseline;
}

.header-bar .masthead{
	margin:0;
	margin-right:1.8rem;
}

.page-tools button{
    font-size:1.1rem;
}

.app{
	padding-top:5rem;
}
