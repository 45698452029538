div.modal_backdrop {
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

div.modal_container {
  border: 1px solid black;
  margin: 0 auto;
  z-index: 1;
  max-height: 80%;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: auto;
  top: 15vh;
  position: relative;
}

div.modal_container header {
  background: navy;
  color: white;
  padding: 0.4em 0.8em;
}

div.modal_container header h1 {
  margin: 0;
}

div.modal_container header button {
  float: right;
}

div.modal_container footer button.cancel {
  background: darkred;
}

div.modal_container footer {
  background: #ddd;
  padding: 0.4em 0.8em;
}

div.modal_container footer button {
  margin-right: 0.4em;
}

div.modal_container div.modal_body {
  background: white;
  padding: 0.8em;
}
