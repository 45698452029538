@import url('https://fonts.googleapis.com/css?family=Roboto');

body, input, textarea, button, select{
	font-family: 'Roboto', sans-serif;
	font-size:1rem;
}

h1, h2, h3, h4, h5, h6{
	font-weight:400;
}

.app{
	max-width:1024px;
	margin:0 auto;
}

h1.masthead{
	font-size:2.7rem;
	margin:0.5rem 0;
	text-transform:uppercase;
	font-weight:100;
	letter-spacing:0.08rem;
	text-shadow:2px 2px 5px grey;
}
