.picture_uploader .file_button{
	display:none;
}

.picture_uploader .preview_thumb{
	max-width:15%;
	max-height:150px;
}

.picture_uploader .preview{
	padding:0.1em;
	display:inline-block;
	width:100%;
}

.picture_uploader .preview .preview_tools{
	display:inline-block;
    width: 83%;
    margin-left:1%;
}

.picture_uploader .preview .preview_tools > textarea{
	width:100%;
    height:2em;
    margin-bottom:0.2em;
	/* someone needs to fix these valign issues */
}

.uploading{
	opacity:0.7;
}
