.browsing_mode .option{
	display:inline-block;
	margin-right:1em;
}

button.warning{
	background:darkred;
	font-weight:bold;
}

.photos_container{
	margin-top:0.8em;
}


.album_browser .description{
	max-width:60rem;
	text-align:center;
	width:100%;
}

.album_browser .separator{
	text-align:center;
	margin:1.4em 0;
}

.album_browser .separator .diamond{
	margin:0 5%;
	display:inline-block;
}

.album_browser h2{
	margin:0.8em 0;
	text-align:center;
}
@media (min-width:800px){
	.album_browser .separator .diamond{
		margin: 0 3.3em;
	}

	.album_browser .description{
		width:700px;
		margin:0 auto;
	}
}

.album-tools{
	margin-bottom:0.3rem;
}


