.banner{
  border:1px solid #4a4;
  border-radius:5px;
  padding:0.5em;
  margin-bottom:1.5em;
}

.banner p{
  margin:0;
}
