.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header h1 {
  margin: 0;
}

.thumbnail {
  display: inline-block;
  position: relative;
  width: 33%;
  margin-bottom: 20px;
}

.thumbnail button {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.thumbnail:hover button {
  visibility: visible;
}