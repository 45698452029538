.photo_strip{
	border-top:1px solid #ccc;
	padding:0.1em;
	position:relative;
	overflow-x:auto;
}

.photo_strip .photo {
	margin:0.1em;
	height:6em;
	width:auto;
}

.photo_strip .tag{
	position:absolute;
	right:0%;
	top:0;
	display:none;
	background:darkred;
	padding:0.4em; 
	color:white;
	font-weight:bold;
}

.photo_strip .navigator_button{
	height:6em;
	vertical-align:top;
}

.photo_strip .navigator_button.disabled{
	background:grey;
}

.photo_strip .navigator_button.left{
	float:left;
}

.photo_strip .navigator_button.right{
	float:right;
}

.photo_strip .video{
	position:relative;
	display:inline-block;
}

.photo_strip .video img.photo{
	opacity:0.7;
}

.photo_strip .video .video_label{
	position:absolute;
	margin:0 auto;
	width:32px;
	height:32px;
	left:5px;
	top:5px;
}

