.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
}

.inputContainer {
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.helpText{
  margin:0;
  margin-top: 4px;
  font-size: 90%;
}


