.video-viewer{
    text-align:center;
}

.video-viewer video{
    max-width:85%;
    height:auto;
    margin:0 auto;

}

.video-viewer .id_header{
    text-align:center;
    margin-bottom:0;
}