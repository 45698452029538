.post{
    padding:0.5em;
}

.post header{
    margin-bottom:0.5em;
}

.post header h1{
    margin: 0.2em 0;
}

.post .goto-album-button{
	margin-left:0.5rem;
	display:inline-block;
}

.post .admin-tools{
	font-size:1rem;
}

.post .album-link-wrapper{
    position: relative;
    border: 2px solid transparent;
}

.post .album-link-wrapper:hover{
    cursor: pointer;
    border: 2px solid green;
}

.post .album-link-wrapper .album-link-label{
    top:0;
    right:0;
    position: absolute;
    z-index: 2;
    background: darkgreen;
    color: white;
    display: none;
    padding: 0.4rem;
    font-weight: 700;
}

.post .album-link-wrapper:hover .album-link-label{
    display:block;
}
.post .photostrip-link{
    border: 2px solid #ccc;
    display: block;
}

.post .photostrip-link:hover{
    border:2px solid darkgreen;
}
