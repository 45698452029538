.post_group > .header{
	font-size:1.9em;
	font-weight:bold;
	margin-bottom:0.3em;
	text-align:center;
}

.post_group > .post {
	margin: 1.2em 0;
}

.post_group > .post .content{
	max-width:60%;
}

.post_group {
	margin-bottom: 1em;
}

.post_group > .post > .title{
	margin:0;
	font-size:1.3em;
}

.reorder-button{
	z-index:3;
}

