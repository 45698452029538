.slideshow{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
    z-index:3;
}

.slideshow > .navigation_button{
    color:white;
    background:none;
    border:none;
    font-size:3em;
    position:fixed;
    top:50%;
    transform:translateY(-50%);
    display:block;
    z-index:3;
    height:auto;
}

.slideshow > .navigation_button.left{
    left:2%;
}

.slideshow > .navigation_button.right{
    right:2%;
}

.slideshow .status{
    z-index:2;
    position:absolute;
    top:1em;
    left:1em;
    font-weight:bold;
    color:white;
    background:rgba(0,0,0, 0.95);
    padding:0.5em;
    font-size:120%;
    border-radius:8px;
}

.slideshow .interval_display{
    background:rgba(0,0,0, 0.95);
    color:white;
    font-weight:bold;
    font-size:1.2em;
}


.slideshow.fullscreen .slideshow_viewer{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.fullscreen_toggler{
    bottom:0;
    right:0;
    position:fixed;
    z-index:2;
	display:flex;
    align-items: stretch;
}

.fullscreen_toggler .ey-label{
    display: inline-block;
    height:100%;
}

.slideshow.fullscreen .slideshow_viewer .caption{
    position:absolute;
    color:white;
    z-index: 1;
    bottom:0;
    background:rgba(0, 0, 0, 0.8);
    text-align:center;
    width:100%;
    margin-bottom:0;
    font-size:1.2em;
}
