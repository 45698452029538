.work-button-indicator{
	height:1rem;
	color:black;
}

.button_strip{
	border:1px inset darkgreen;
	display:inline-block;
}

.button_strip input[type="checkbox"]{
	display:inline;
	width:auto;
}

.ey-button-group{
    display:flex;
}

button, .ey-dropdown-label, .ey-label{
	background:rgb(127, 246, 226);
	padding:0 0.6rem;
	line-height:1.8rem;
	border:1px solid;
	border-top-color:rgb(212, 255, 248);
	border-left-color: rgb(212, 255, 248);
	border-right-color: rgb(59, 202, 166);
	border-bottom-color: rgb(59, 202, 166);
	box-sizing: border-box;
}

button:hover, .ey-dropdown:hover .ey-dropdown-label{
	border-right-color: rgb(173, 173, 211);
	border-bottom-color: rgb(173, 173, 211);
	border-left-color: rgb(211, 211, 235);
	border-top-color: rgb(211, 211, 235);

	background: rgb(200, 200, 240);
	cursor: pointer;
}
