.ey-dropdown{
    position:relative;
    box-sizing: border-box;
	display:inline-block;
}

.ey-dropdown-label{
    display:inline-block;
    box-sizing:border-box;
}

.ey-dropdown-label:after{
    content: "+";
    width:1rem;
    text-align:right;
    display:inline-block;
    font-weight:900;
}

.ey-dropdown ul{
    position:absolute;
    display:none;
    padding:0.25rem 0;
    list-style:none;
    margin:0;
    width:130%;
    background:white;
    z-index:5;
    border:2px solid rgb(182, 222, 238);
    border-radius:2px;
    border-top-left-radius: 0;
}

.ey-dropdown:hover ul{
    display:block;
}

.ey-dropdown-item{
    text-decoration: none;
    display:block;
    width:100%;
    padding:0.15rem 0.2rem;
    margin: 0.15rem 0;
    box-sizing: border-box;
}

.ey-dropdown-item:hover{
    background:lightblue;
}
