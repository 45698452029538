.image{
  display: inline-block;
  position: relative;
  width: 33%;
  box-sizing: border-box;
  border: 5px solid transparent;
}

.selected{
  composes: image;
  border: 5px solid red;
}

.unselected{
  composes: image;
}

.disabledPhoto{
  composes: unselected;
  opacity: 40%;
}

.footer{
  position: fixed;
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 16px;
  border-top: 1px solid darkgreen;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  background: white;
}

.footer button{
  margin-right: 8px;
}

.videoSelector{
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.image:hover .videoSelector{
  visibility: visible;;
}

.spacer {
  height: 160px;
}

.photo {
  width: 100%;
}