div.photo_viewer .caption {
  text-align: center;
}

div.photo_viewer div.id_header {
  text-align: center;
}

div.photo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

div.photo_viewer .photo {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
