
.dragging{
	opacity:0.6;
	
}

.hover-left:before, .hover-right:after{
	content: '';
	display:inline-block;
	background:black;
	width:0.5rem;
	height:95%;
	box-sizing: border-box;
}

