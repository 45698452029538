.separator {
  display: block;
  padding: 4px 0;
  font-weight: bold;
  font-size: 1.6rem;
}

.yearSeparator{
  composes: separator;
  text-align: left;
  flex-basis: 300px
}

.albumSeparator{
  composes: separator;
  flex-grow: 1;
  text-align: right;
}

.separatorContainer {
  display: flex;
  background: rgb(189, 192, 188);
  padding: 0 12px;
  margin-right: 100px;
  margin: 12px 0;
}
