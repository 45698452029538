.edit_subscriptions_modal .email{
    color:blue;
    text-decoration:none;
}

.edit_subscriptions_modal .email.disabled{
    text-decoration:line-through;
}

.edit_subscriptions_modal .inline-forms form{
    display:inline-block;
    margin-right:3em;
}

.edit_subscriptions_modal td{
    padding:0.25em 0.2em;
}

.edit_subscriptions_modal tr.even{
    background:#eee;
}