.alert-box{
	font-weight:500;
	line-height:3rem;
	vertical-align:middle;
	padding:0 0.3rem;
	font-size:1.1rem;
	border: 2px solid currentColor;
	margin:0.3rem 0;
}

.alert-box:before{
	font-size:2rem;
	margin-right:0.4rem;
	padding:0 0.8rem;
	color:white;
	border-radius:3px;
	vertical-align:middle;
}

.error-box{
	color:maroon;
}

.error-box:before{
	content:"!";
	background:maroon;
}

.info-box{
	color:teal;
}

.info-box:before{
	content: "*";
	background:teal;
}
