.root{
}

.folders {
  margin-bottom: 40px;
}

.folder {
  padding: 8px;
  border: 1px solid #bbb;
  border-radius: 5px;
  width: 275px;
  text-decoration: none;
}

.folder:hover {
  border-color: #007918;
}

.foldersList {
  display: flex;
  margin: 8px 0;
  gap: 8px;
}

.thumbnails {
  height: 10rem;
}

.folderName {
  font-weight: bold;
}

.folderFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
}

.folderCount {
  color: #888;
}

.photo {
  width: 33%;
  margin-bottom: 20px;
  text-align: center;
}
