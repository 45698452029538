.photo_list {
}

.photo_list .photo_row {
  margin-bottom: 2em;
}

.photo_list .wrapper {
  box-sizing: border-box;
  padding: 1%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
}

@media (min-width: 700px) {
  .photo_list .wrapper {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .photo_list .wrapper {
    width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .photo_list .wrapper {
    width: 300px;
  }
}

.photo_list .reorder-button {
  position: absolute;
  z-index: 5;
}

.photo_list .wrapper img {
  max-width: 80%;
  max-height: 100%;
  padding: 0.2em;
  border: 1px solid lightgray;
  margin: 0 auto;
  display: block;
}

.photo_list p.photo_id_text {
  text-align: center;
  margin-bottom: 0.1em;
}

.photo_list p.caption {
  text-align: center;
}

.photo_list .wrapper img:hover {
  border-color: black;
}

.photo_list .wrapper.selected {
  background: #ccc;
  border-radius: 5px;
}
