.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.headerMain {
  text-align: center;
}

.headerMain h2{
  margin:0;
  margin-bottom: 0.5rem;
}

.prevLink, .nextLink{
  flex-basis: 300px;
}

.prevLink{
  text-align: right;
}